import { createStore } from "vuex";

import axios from "axios";
import routes from "../routes";
import Helper from "../helper";

axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

// Create a new store instance.
const store = createStore({
  state: {
    url: {
      cockpitBasePath: process.env.VUE_APP_API_BASE_PATH,
    },
    // selectedBranchId:null,
    // allAvailableBusiness:[],
    isTestDataMode: false,
    isPageLoading: true,
    lightSpeedSuccessHandler: {},
    purchaseDetails: {},
    companyId: "",
    businessLocationId: "",
    accountId: "",
    callBackUrlRSeries: "",
    saleId: "",
    employeeId: "",
    customerId: "",
    shopId: "",
    count: 0,
    member: {},
    identificationFormData: {
      phoneNumber: "",
      appClientId: "",
      customIdentifier: "",
      email: "",
      memberId: "",
    },
    registrationFormData: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      // govID: "",
      // comoMemberId: "",
    },
    applicationSettings: {},
    /**This will use to store selected gift card from given list */
    appliedAssets: [],
    /** This will use to store gift Codes*/
    giftCodes: [],
    /** This is use to store the combination giftCode and Assets in
     * detail details returned data from server */
    benefitDetails: {},
    submitPurchaseResponse: {},
    paymentResponse: {},
    vatInclusive: undefined,
    verificationCode: "",
    /**The amount that you wanna pay using Como */
    payCreditAmount: 0,
    /** To prevent discount Distributed again */
    isGuest: false,
  },
  mutations: {
    // updateSelectedBranchId(state,value){
    //   state.selectedBranchId=value;
    // },
    // updateAllAvailableBusiness(state,value){
    //   state.allAvailableBusiness=value;
    // },
    enableTestDataMode(state) {
      state.isTestDataMode = true;
    },
    increment(state) {
      state.count++;
    },
    updateIsPageLoading(state, data) {
      state.isPageLoading = data;
    },
    updateLightSpeedSuccessHandler(state, data) {
      state.lightSpeedSuccessHandler = data;
    },
    updatePurchaseDetails(state, data) {
      state.purchaseDetails = data;
    },
    updateVerificationCode(state, data) {
      state.verificationCode = data;
    },
    updateCompanyId(state, data) {
      state.companyId = data;
    },
    updateBusinessLocationId(state, data) {
      state.businessLocationId = data;
    },
    updateAccountId(state, data) {
      state.accountId = data;
    },
    updateCallBackUrlRSeries(state, data) {
      state.callBackUrlRSeries = data;
    },
    updateEmployeeId(state, data) {
      state.employeeId = data;
    },
    updateCustomerId(state, data) {
      state.customerId = data;
    },
    updateSaleId(state, data) {
      state.saleId = data;
    },
    updateShopId(state, data) {
      state.shopId = data;
    },

    updateMember(state, data) {
      state.member = data;
    },
    updateIdentificationFormData(state, data) {
      state.identificationFormData = data;
    },
    updateApplicationSettings(state, data) {
      state.applicationSettings = data;
    },
    updateAsset(state, dataArray) {
      state.appliedAssets = dataArray;
    },
    updateVatInclusive(state, data) {
      state.vatInclusive = data;
    },
    addGiftCode(state, code) {
      // Add code only if not exist
      if (state.giftCodes.indexOf(code) < 0) {
        state.giftCodes.push(code);
      }
    },
    /**
     *
     * @param {Object} state
     * @param {Object|String} asset
     */
    removeGiftCode(state, asset) {
      let code = asset.code ? asset.code : asset;
      state.giftCodes = state.giftCodes.filter((gifts) => gifts != code);
    },

    /**
     *
     * @param {Object} state
     * @param {Number|String} payCreditAmount
     */
    updatePayCreditAmount(state, payCreditAmount) {
      state.payCreditAmount = payCreditAmount;
    },

    /**
     *
     * @param {Object} state
     * @param {Object} assetData Asset Object
     */
    removeAsset(state, assetData) {
      state.appliedAssets = state.appliedAssets.filter(
        (asset) => asset.key !== assetData.key
      );
    },
    updateBenefitDetails(store, data) {
      store.benefitDetails = data;
    },
    updateSubmitPurchaseResponse(store, data) {
      store.submitPurchaseResponse = data;
    },
    updatePaymentResponse(store, data) {
      store.paymentResponse = data;
    },
    updateIsGuest(store, data) {
      store.isGuest = data;
    },
  },
  actions: {
    START_LOADING({ commit }) {
      commit("updateIsPageLoading", true);
    },
    STOP_LOADING({ commit }) {
      commit("updateIsPageLoading", false);
    },
    /**
     * Go Back to previous page
     */
    BACK() {
      routes.go(-1);
    },
    /**
     * Go to next visited page
     */
    FORWARD() {
      routes.go(1);
    },

    /**Get Application Settings Details */
    GET_APPLICATION_SETTINGS({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            Helper.path.joinApiPath(`como-application-settings`),
            getters.axiosConfig
          )
          .then((response) => {
            commit("updateApplicationSettings", response.data.data);
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    /**Get All Business Locations Details */
    GET_SALE_DETAILS({ getters }) {
      return new Promise((resolve, reject) => {
        let query = `saleId=${getters.saleId}`;
        if (getters.customerId) {
          query += `&customerId=${getters.customerId}`;
        }
        if (getters.employeeId) {
          query += `&employeeId=${getters.employeeId}`;
        }

        // if (getters.shopId) {
        //   query += `&shopId=${getters.shopId}`;
        // }

        axios
          .get(
            Helper.path.joinApiPath(`r-series/get-sale-details?`) + query,
            getters.axiosConfig
          )
          .then((response) => {
            // commit('updateSaleDetail', response.data);
            console.log(response);
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    /**Get All Business Locations Details */
    // GET_ALL_BUSINESS({ commit,getters }) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(Helper.path.joinApiPath(`business-locations`),getters.axiosConfig)
    //       .then(response => {
    //         commit('updateAllAvailableBusiness', response.data);
    //         resolve(response);
    //       })
    //       .catch(err => reject(err));
    //   })
    // },
    /**
     * Call Api to get Member Details
     * @returns
     */
    GET_MEMBER_DETAILS({ commit }) {
      return new Promise((resolve, reject) => {
        let data = {
          customer: this.getters.identificationFormData,
          purchase: this.getters.purchaseDetails,
        };
        axios
          .post(
            Helper.path.joinApiPath(`member-details`),
            data,
            this.getters.axiosConfig
          )
          .then((response) => {
            commit("updateMember", response.data.membership);
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    QUICK_REGISTER_MEMBER({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            Helper.path.joinApiPath(`quick-register-member`),
            this.getters.identificationFormData,
            this.getters.axiosConfig
          )
          .then((response) => {
            commit("updateMember", response.data.membership);
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    /**
     * Call Api to Register New Member on Como
     * @returns
     */

    REGISTER_MEMBER() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            Helper.path.joinApiPath(`register-member`),
            this.getters.registrationFormData,
            this.getters.axiosConfig
          )
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * Call Api to get benefits of current Asset Cards and Deals
     * @returns
     */

    GET_BENEFIT({ commit, getters }) {
      return new Promise((resolve, reject) => {
        let data = {};
        data.purchase = getters.purchaseDetails;
        data.customers = [
          {
            phoneNumber: getters.member.phoneNumber,
          },
        ];
        data.vatInclusive = getters.vatInclusive;
        data.isGuest = getters.isGuest;
        data.redeemAssets = [];
        getters.appliedAssets.forEach((element) => {
          data.redeemAssets.push({
            key: element.key,
          });
        });
        getters.giftCodes.forEach((code) => {
          data.redeemAssets.push({
            code: code,
          });
        });

        axios
          .post(
            Helper.path.joinApiPath(`get-benefits`),
            data,
            this.getters.axiosConfig
          )
          .then((response) => {
            commit("updateBenefitDetails", response.data);
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },

    /**
     * Once purchase payment is completed send Submit Purchase
     * request to submit the purchase
     * @returns
     */

    SUBMIT_PURCHASE({ getters }) {
      return new Promise((resolve, reject) => {
        let data = {};
        data.purchase = getters.purchaseDetails;
        data.customers = [
          {
            phoneNumber: getters.member.phoneNumber,
          },
        ];

        if (
          getters.payCreditAmount &&
          getters.paymentResponse.payments.length
        ) {
          if (getters.paymentResponse.payments[0].paymentMethod == "discount") {
            let totalGeneralDiscount = Math.abs(
              getters.paymentResponse.payments.reduce(
                (acc, payment) => (acc += payment.amount),
                0
              )
            );
            data.purchase.totalAmount -= totalGeneralDiscount;

            // Round the number
            data.purchase.totalAmount = Math.round(data.purchase.totalAmount);

            data.purchase.totalGeneralDiscount = totalGeneralDiscount;
          }
          if (
            getters.paymentResponse.payments[0].paymentMethod == "meanOfPayment"
          ) {
            data.purchase.meanOfPayment = [];
            data.purchase.meanOfPayment.push({
              type: "memberCredit",
              amount: Math.abs(
                getters.paymentResponse.payments.reduce(
                  (acc, payment) => (acc += payment.amount),
                  0
                )
              ),
            });
          }
        }

        data.deals = [];
        getters.benefitDetails.deals.forEach((deal) => {
          data.deals.push({
            key: deal.key,
            appliedAmount: deal.benefits.reduce(
              (acc, benefit) => (acc += benefit.sum),
              0
            ),
          });
        });
        data.redeemAssets = [];
        getters.redeemAssetsDetails.forEach((asset) => {
          if (asset.key) {
            let appliedAmount = asset.benefits
              ? asset.benefits.reduce((acc, benefit) => (acc += benefit.sum), 0)
              : 0;
            data.redeemAssets.push({
              key: asset.key,
              appliedAmount: appliedAmount ? appliedAmount : 0,
            });
          }
        });

        axios
          .post(
            Helper.path.joinApiPath(`submit-purchase-details`),
            data,
            this.getters.axiosConfig
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    /**
     * Send Post Request with verification Code received on Submit
     * Purchase call to cancel your purchase
     * @returns
     */

    CANCEL_PURCHASE() {
      return new Promise((resolve, reject) => {
        let data = {};
        data.purchase = this.getters.purchaseDetails;
        data.customers = [
          {
            phoneNumber: this.getters.member.phoneNumber,
          },
        ];
        data.confirmation = this.getters.submitPurchaseResponse.confirmation;

        data.redeemAssets = [];
        this.getters.redeemAssetsDetails.forEach((asset) => {
          if (asset.key) {
            data.redeemAssets.push({
              key: asset.key,
              appliedAmount: asset.benefits
                ? asset.benefits.reduce(
                    (acc, benefit) => (acc += benefit.sum),
                    0
                  )
                : 0,
            });
          }
        });

        axios
          .post(
            Helper.path.joinApiPath(`submit-purchase-details`),
            data,
            this.getters.axiosConfig
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    /**
     * Send Post Request to pay for you purchase
     * @returns Promise
     */
    SUBMIT_PAYMENT({ getters }) {
      return new Promise((resolve, reject) => {
        let data = {};
        data.purchase = getters.purchaseDetails;
        data.customer = {
          phoneNumber: getters.member.phoneNumber,
        };
        data.deals = [];
        if (getters.benefitDetails.deals) {
          getters.benefitDetails.deals.forEach((deal) => {
            data.deals.push({
              key: deal.key,
              appliedAmount: deal.benefits.reduce(
                (acc, benefit) => (acc += benefit.sum),
                0
              ),
            });
          });
        }
        data.redeemAssets = [];
        getters.redeemAssetsDetails.forEach((asset) => {
          if (asset.key) {
            data.redeemAssets.push({
              key: asset.key,
              appliedAmount: asset.benefits
                ? asset.benefits.reduce(
                    (acc, benefit) => (acc += benefit.sum),
                    0
                  )
                : 0,
            });
          }
        });

        data.amount = Math.round(Number(getters.payCreditAmount) * 100);
        // data.amount = getters.purchaseDetails.totalAmount;
        if (getters.verificationCode) {
          data.verificationCode = getters.verificationCode;
        }
        axios
          .post(
            Helper.path.joinApiPath(`submit-payment`),
            data,
            this.getters.axiosConfig
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    /**
     * Send Post Request with verification Code received on Submit Payment call to cancel your Payment
     * @returns
     */

    CANCEL_PAYMENT() {
      return new Promise((resolve, reject) => {
        let data = {};
        data.customer = {
          phoneNumber: this.getters.member.phoneNumber,
        };
        data.confirmation = this.getters.paymentResponse.confirmation;
        data.purchase = this.getters.purchaseDetails;

        axios
          .post(
            Helper.path.joinApiPath(`cancel-payment`),
            data,
            this.getters.axiosConfig
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    DISTRIBUTE_DISCOUNT({ getters }) {
      if (
        !(
          getters.benefitDetails.totalDiscountsSum &&
          getters.purchaseDetails.totalAmount
        )
      ) {
        return;
      }

      // Distribute Deals Discounts
      if (getters.benefitDetails.deals) {
        getters.benefitDetails.deals.forEach((deal) => {
          let benefitLength = deal.benefits.length;
          for (let i = 0; i < benefitLength; i++) {
            let benefit = deal.benefits[i];
            /** This will Apply discounts on items and will change net amount of item */
            benefit.extendedData.forEach((data) => {
              let item = getters.purchaseDetails.items.find((item) => {
                return item.code === data.item.code;
              });
              item.netAmount = data.item.netAmount + data.discount;
              return benefit;
            });
          }
        });
      }

      //Distribute Redeem Assets Discounts
      getters.redeemAssetsDetails.forEach((asset) => {
        if (!asset.benefits) {
          return;
        }
        let benefitLength = asset.benefits.length;
        for (let i = 0; i < benefitLength; i++) {
          let benefit = asset.benefits[i];
          if (!benefit.extendedData) {
            continue;
          }
          benefit.extendedData.forEach((data) => {
            let item = getters.purchaseDetails.items.find((item) => {
              return item.code === data.item.code;
            });
            item.netAmount += data.discount;
            return benefit;
          });
        }
      });

      /** This failed in case of some extra discount rather than discount on items */
      // Sum of items Net Amount
      if (getters.businessLocationId) {
        let totalSum = getters.lightSpeedSuccessHandler.transactionLines.reduce(
          (acc, item) => (acc += item.amount),
          0
        );
        getters.purchaseDetails.totalAmount =
          totalSum + getters.benefitDetails.totalDiscountsSum;
      } else if (getters.companyId) {
        getters.purchaseDetails.totalAmount = Math.round(
          getters.lightSpeedSuccessHandler.total * 100 +
            getters.benefitDetails.totalDiscountsSum
        );
      } else {
        getters.purchaseDetails.totalAmount =
          getters.lightSpeedSuccessHandler.sale.totalAmount +
          getters.benefitDetails.totalDiscountsSum;
      }

      return;
    },

    RESET_PURCHASE_DETAILS({ getters, commit }) {
      // let purchaseDetails = getters.purchaseDetails;
      let originalPurchaseDetails;
      if (getters.businessLocationId) {
        originalPurchaseDetails = Helper.convertKSeriesIntoComoObject(
          getters.lightSpeedSuccessHandler
        );
      } else if (getters.accountId) {
        originalPurchaseDetails = Helper.convertRSeriesIntoComoObject(
          getters.lightSpeedSuccessHandler.sale
        );
      } else {
        /**
         * L series Does not pass the open time of sale so we used Date.now()
         * method for date to not change sale open time
         * use last openTime as como uses this time to distinguish the sales.
         */
        originalPurchaseDetails = Helper.convertIntoComoObject(
          getters.lightSpeedSuccessHandler,
          getters.purchaseDetails.openTime
        );
      }
      commit("updatePurchaseDetails", originalPurchaseDetails);
    },
    /**
     * If Verification code is required this Action will be used to send verification Code
     */
    SEND_VERIFICATION_CODE() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            Helper.path.joinApiPath(`send-verification`),
            {
              phoneNumber: this.getters.member.phoneNumber,
            },
            this.getters.axiosConfig
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    LOG_LIGHTSPEED_RECEIPT() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            Helper.path.joinApiPath(`lightspeed-receipt`),
            {
              data: this.getters.lightSpeedSuccessHandler,
            },
            this.getters.axiosConfig
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    isTestDataMode: (state) => state.isTestDataMode,
    count: (state) => state.count,
    isPageLoading: (state) => state.isPageLoading,
    member: (state) => state.member,
    url: (state) => state.url,
    companyId: (state) => state.companyId,
    businessLocationId: (state) => state.businessLocationId,
    identificationFormData: (state) => state.identificationFormData,
    registrationFormData: (state) => state.registrationFormData,
    applicationSettings: (state) => state.applicationSettings,
    comoDiscountItemId: (state) => {
      let comoDiscountItemId;
      if (state.applicationSettings["business_locations"]) {
        state.applicationSettings.business_locations.forEach(
          (businessLocation) => {
            if (
              businessLocation.location_id == state.businessLocationId &&
              businessLocation.como_discount_item_id != ""
            ) {
              comoDiscountItemId = businessLocation.como_discount_item_id;
            }
          }
        );
      }
      return comoDiscountItemId;
    },
    currency: (state) => state.applicationSettings.currency,
    appliedAssets: (state) => state.appliedAssets,
    giftCodes: (state) => state.giftCodes,
    benefitDetails: (state) => state.benefitDetails,
    /**This will return actual redeemAsset list with details */
    redeemAssetsDetails: (state) => {
      if (state.benefitDetails && state.benefitDetails.redeemAssets) {
        return state.benefitDetails.redeemAssets;
      }
      return [];
    },
    lightSpeedSuccessHandler: (state) => state.lightSpeedSuccessHandler,
    purchaseDetails: (state) => state.purchaseDetails,
    submitPurchaseResponse: (state) => state.submitPurchaseResponse,
    paymentResponse: (state) => state.paymentResponse,
    verificationCode: (state) => state.verificationCode,
    payCreditAmount: (state) => state.payCreditAmount,
    vatInclusive: (state) => state.vatInclusive,
    isGuest: (state) => state.isGuest,
    accountId: (state) => state.accountId,
    callBackUrlRSeries: (state) => state.callBackUrlRSeries,
    saleId: (state) => state.saleId,
    employeeId: (state) => state.employeeId,
    customerId: (state) => state.customerId,
    shopId: (state) => state.shopId,

    // selectedBranchId: (state) => {
    //   if(!state.selectedBranchId&&localStorage.getItem('branchId')){
    //     state.selectedBranchId=localStorage.getItem('branchId');
    //   }
    //   return state.selectedBranchId;
    // },
    // allAvailableBusiness: (state) => state.allAvailableBusiness,
    axiosConfig: (getters) => {
      if (getters.businessLocationId) {
        return {
          params: {
            businessLocationId: getters.businessLocationId,
          },
        };
      }
      /**
       * This part is for the R Series and assuming shopId would always be present.
       */
      if (getters.accountId) {
        return {
          params: {
            accountId: getters.accountId,
            shopId: getters.shopId,
          },
        };
      }
      return {
        params: {
          companyId: getters.companyId,
          // branchId: getters.selectedBranchId
        },
      };
    },
  },
});

export default store;
