<template>
  <div>
    <ErrorResponseHandle :errors="errors" />
    <div id="error-response"></div>
    <div class="p-2 pt-6">
      <h3
        v-if="getFullName"
        class="text-center text-xl capitalize text-gray-900 font-medium leading-8"
      >
        {{ getFullName }}
      </h3>
      <div class="p-4 px-12">
        <p class="mb-6 italic">
          Costs are calculated based on values you have Added in Cart
        </p>
        <div
          v-if="member.creditBalance && member.creditBalance.usedByPayment"
          class="flex justify-between border-b"
        >
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Total Available Credit Balance
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-blue-600"
          >
            {{
              $filters.convertFromCent(member.creditBalance.balance.monetary)
            }}
          </div>
        </div>
        <div
          v-if="member.pointsBalance && member.pointsBalance.usedByPayment"
          class="flex justify-between border-b"
        >
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Total Available Points Balance
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-gray-900"
          >
            {{
              $filters.convertFromCent(member.pointsBalance.balance.monetary)
            }}
          </div>
        </div>
        <div class="flex justify-between border-b">
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Subtotal
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-gray-900"
          >
            {{ $filters.formatCurrencyUSD(getTotalAmount) }}
          </div>
        </div>
        <div class="flex justify-between border-b">
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Total Discount
          </div>
          <div class="lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-green-700">
            -
            {{
              $filters.formatCurrencyUSD(
                Math.abs(benefitDetails.totalDiscountsSum || 0)
              )
            }}
          </div>
        </div>
        <div v-if="payCreditAmount > 0" class="flex justify-between border-b">
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Pay with Credit
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-red-500"
          >
            {{ $filters.formatCurrencyUSD(payCreditAmount * 100) }}
          </div>
        </div>
        <div class="flex justify-between pt-4 border-b">
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            New Subtotal
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-gray-900"
          >
            {{ $filters.formatCurrencyUSD(getNetAmount) }}
          </div>
        </div>
        <div class="flex justify-between pt-4 border-b">
          <div
            class="text-left lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
          >
            Total
          </div>
          <div
            class="text-right whitespace-nowrap lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-gray-900"
          >
            {{ $filters.formatCurrencyUSD(getNetAmount) }}
          </div>
        </div>
        <div
          v-if="member.phoneNumber"
          class="flex justify-center pt-4 border-b"
        >
          <div class="p-4 flex justify-center">
            <input
              ref="payCreditAmountInput"
              v-model="payCreditAmount"
              type="number"
              inputmode="numeric"
              class="w-24 rounded-l-lg p-3 border-t outline-none mr-0 border-b border-l text-gray-800 border-gray-200 bg-white disabled:opacity-50 disabled:cursor-not-allowed"
              placeholder="Amount"
              min="0"
              :disabled="isPaymentSubmitted || !maxPayableAmount"
              :max="maxPayableAmount"
              pattern="[0-9]+"
              step=".01"
            />
            <button
              class="rounded-r-lg bg-blue-400 text-gray-800 font-bold p-3 uppercase border-blue-400 focus:outline-none hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed outline-none border-t border-b border-r"
              :disabled="isPaymentSubmitted || !maxPayableAmount"
              @click="maxPayable"
            >
              Max
            </button>
          </div>
        </div>
      </div>
      <div class="px-8 md:px-12">
        <!-- <div class="p-4">
          <ul class="list-disc text-left">
            <li
              v-for="error in errors"
              :key="error.code"
              class="text-red-500"
            >
              {{ error.message }}
            </li>
          </ul>
        </div> -->
        <div v-if="isVerificationCodeRequired" class="mb-4 p-4 md:px-12">
          <div class="w-full flex justify-center">
            <input
              v-model="verificationCode"
              class="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              required
              placeholder="Verification Code"
            />
            <button
              class="flex text-sm md:text-normal ml-4 md:ml-8 rounded-lg bg-blue-400 text-gray-800 font-bold p-3 uppercase focus:outline-none hover:opacity-90 outline-none"
              :class="{
                'cursor-not-allowed opacity-90': isSendingVerificationCode,
              }"
              :disabled="isSendingVerificationCode"
              @click.prevent="sendVerificationCode"
            >
              <span> send Code </span>
              <LoaderInsideButton :is-loading="isSendingVerificationCode" />
            </button>
          </div>
          <!-- <div class="flex flex-wrap justify-center pt-8">
            <button-secondary
              text="Cancel Payment"
              btn-type="danger"
              :disabled="!isPaymentSubmitted"
              class="m-2"
              @click="cancelPayment"
            >
              <LoaderInsideButton :is-loading="isLoadingPaymentCancel" />
            </button-secondary>
            <button-secondary
              text="Submit Payment"
              btn-type="success"
              :disabled="
                isPaymentSubmitted ||
                  (!verificationCode && isVerificationCodeRequired)
              "
              class="m-2"
              @click="submitPayment"
            >
              <LoaderInsideButton :is-loading="isLoadingPaymentSubmit" />
            </button-secondary>
          </div> -->
        </div>

        <button-primary
          v-if="
            payCreditAmount && Number(payCreditAmount) && !isPaymentSubmitted
          "
          text="Submit Payment"
          type="success"
          :disabled="!verificationCode && isVerificationCodeRequired"
          @click="submitPayment"
        >
          <LoaderInsideButton :is-loading="isLoadingPaymentSubmit" />
        </button-primary>

        <button-primary
          v-else
          text="Complete Purchase"
          type="success"
          btn-type="success"
          class="font-bold"
          :disabled="!isPaymentSubmitted && !!Number(payCreditAmount)"
          @click="finishPurchase"
        >
          <LoaderInsideButton :is-loading="isLoadingPaymentSubmit" />
        </button-primary>
      </div>
      <div class="px-8 md:px-12">
        <button-primary
          v-if="isPaymentSubmitted"
          text="cancel Payment"
          btn-type="danger"
          :disabled="!isPaymentSubmitted || isLoadingPaymentCancel"
          class="font-bold"
          @click="cancelPayment"
        >
          <LoaderInsideButton :is-loading="isLoadingPaymentCancel" />
        </button-primary>
      </div>
    </div>

    <div class="flex justify-center">
      <ButtonSecondary btn-type="gray" text="Back" @click="goBack" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ButtonSecondary from "./partials/ButtonSecondary.vue";
import ButtonPrimary from "./partials/ButtonPrimary.vue";
import LoaderInsideButton from "./partials/LoaderInsideButton.vue";
import Helper from "../helper";
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
export default {
  components: {
    ButtonPrimary,
    LoaderInsideButton,
    ButtonSecondary,
    ErrorResponseHandle,
  },
  data() {
    return {
      errors: [],
      isLoadingFinalizePayment: false,
      isLoadingPaymentSubmit: false,
      isPaymentSubmitted: false,
      isPaymentCanceled: false,
      isSendingVerificationCode: false,
      isLoadingPaymentCancel: false,
    };
  },

  computed: {
    ...mapGetters([
      "member",
      "benefitDetails",
      // "payCreditAmount",
      "paymentResponse",
      "purchaseDetails",
      "lightSpeedSuccessHandler",
      "companyId",
      "accountId",
      "callBackUrlRSeries",
      "saleId",
      "shopId",
      "businessLocationId",
      "comoDiscountItemId",
    ]),
    isVerificationCodeRequired() {
      return this.errors.some(
        (error) => error.code == "4007005" || error.code == "4007004"
      );
    },
    verificationCode: {
      get() {
        return this.$store.getters.verificationCode;
      },
      set(value) {
        return this.$store.commit("updateVerificationCode", value);
      },
    },
    appliedAssets: {
      get() {
        return this.$store.getters.appliedAssets;
      },
      set(value) {
        return this.$store.commit("addAsset", value);
      },
    },
    payCreditAmount: {
      get() {
        return this.$store.getters.payCreditAmount;
      },
      set(value) {
        if (value > this.maxPayableAmount) {
          value = this.maxPayableAmount;
        }
        this.$store.commit("updatePayCreditAmount", value);
        this.$refs.payCreditAmountInput.value = this.maxPayableAmount;
        return value;
      },
    },
    getNetAmount() {
      let total = this.purchaseDetails.totalAmount - this.payCreditAmount * 100;
      return total <= 0 ? 0 : total;
    },
    getFullName() {
      let firstName = this.member.firstName ? this.member.firstName : "";
      let lastName = this.member.lastName ? this.member.lastName : "";
      return firstName + lastName.trim();
    },
    getTotalAmount() {
      if (this.businessLocationId) {
        return this.lightSpeedSuccessHandler.totalAmount;
      } else if (this.accountId) {
        return this.lightSpeedSuccessHandler.sale.totalAmount;
      } else return this.lightSpeedSuccessHandler.total * 100;
    },
    usableForPaymentBalance() {
      if (this.member.creditBalance.usedByPayment) {
        return this.member.creditBalance.balance.monetary;
      }
      return this.member.pointsBalance.balance.monetary;
    },
    // creditBalance() {
    //   return this.member.creditBalance.balance.monetary;
    // },
    maxPayableAmount() {
      // if (this.businessLocationId) {
      //   console.log ("maxPayableAmount",
      //     this.creditBalance, this.purchaseDetails.totalAmount / 100 );
      return (
        Math.round(
          Math.min(
            this.usableForPaymentBalance,
            this.purchaseDetails.totalAmount
          )
        ) / 100
      );
      // }
      //         console.log ("maxPayableAmount else",
      //     this.creditBalance, this.purchaseDetails.total  );
      // return Math.min(this.creditBalance, this.purchaseDetails.total) / 100;
    },
    totalDiscountedAmount() {
      let DiscountsSum = this.benefitDetails.totalDiscountsSum || 0;
      return Number(
        Math.abs(DiscountsSum / 100) + Number(this.payCreditAmount)
      );
    },
  },
  mounted() {
    this.getBenefits();
  },
  methods: {
    goBack() {
      this.$store
        .dispatch("RESET_PURCHASE_DETAILS")
        .then(() => {
          this.$store.dispatch("BACK");
        })
        .catch((err) => console.error(err));
    },
    maxPayable() {
      this.payCreditAmount = this.maxPayableAmount;
      this.$refs.payCreditAmountInput.value = this.maxPayableAmount;
    },
    sendVerificationCode() {
      this.isSendingVerificationCode = true;

      this.$store
        .dispatch("SEND_VERIFICATION_CODE")
        .then(() => {
          this.isSendingVerificationCode = false;
        })
        .catch((error) => {
          this.isSendingVerificationCode = false;
          console.error(error);
        });
    },
    cancelPayment() {
      if (this.isLoadingPaymentCancel) {
        return;
      }
      this.isLoadingPaymentCancel = true;
      this.$store.dispatch("CANCEL_PAYMENT").then((resp) => {
        this.$store.commit("updatePaymentResponse", []);
        if (resp.data.type == "memberCredit") {
          this.member.creditBalance.balance.monetary =
            resp.data.updatedBalance.monetary;
        }
        if (resp.data.type == "pointCredit") {
          this.member.pointsBalance.balance.monetary =
            resp.data.updatedBalance.monetary;
        }

        this.verificationCode = "";
        this.$store.commit("updatePayCreditAmount", 0.0);
        this.isPaymentSubmitted = false;
        this.isLoadingPaymentCancel = false;
      });
    },
    /**
     * This method will submit Purchage details to como
     */
    finishPurchase() {
      if (Number(this.payCreditAmount) && !this.isPaymentSubmitted) {
        return;
      }
      this.isLoadingFinalizePayment = true;
      /**Apply Gift Cards */
      try {
        if (this.businessLocationId) {
          /** Apply Discount and close window */
          /** Apply product based discount if discounted product ID is available. */
          if (this.comoDiscountItemId) {
            window.pos_addSpecialItemToCurrentAccount(
              this.comoDiscountItemId.toString(),
              Math.round(this.totalDiscountedAmount * -100),
              "Como Loyalty Discount"
            );
            window.pos_close();
          } else {
            window.pos_addPayment(
              Math.round(this.totalDiscountedAmount * 100),
              "COMO",
              () => {}
            );
            window.pos_close();
          }
        } else if (this.accountId) {
          this.addPaymentSaleLine();
        } else {
          window.LSLoyalty.redeemPointsForValue(this.totalDiscountedAmount);
          window.LSLoyalty.close();
        }
        this.isLoadingPaymentSubmit = false;
        this.isLoadingFinalizePayment = false;
      } catch (err) {
        console.error(err);
        document.getElementById("error-response").innerHTML = err.message;
        this.isLoadingFinalizePayment = false;
      }
    },
    async addPaymentSaleLine() {
      if (this.isLoadingPaymentSubmit) {
        return;
      }
      this.isLoadingPaymentSubmit = true;
      return await this.$axios
        .post(Helper.path.joinApiPath("r-series/add-como-payment-sale-line"), {
          accountId: this.accountId,
          saleId: this.saleId,
          shopId: this.shopId,
          amount: Math.round(this.totalDiscountedAmount * 100),
        })
        .then((res) => {
          console.log("added", res);
          location.replace(this.callBackUrlRSeries);
        })
        .catch((error) => {
          this.errors = [];
          console.error(error.response);
          if (error.response) {
            for (let i in error.response.data) {
              if (typeof error.response.data[i] == "object") {
                for (let j in error.response.data[i]) {
                  this.errors.push(error.response.data[i][j]);
                }
              } else {
                this.errors.push(error.response.data[i]);
              }
            }
            this.isLoadingPaymentSubmit = false;
            // }
          } else if (error.request) {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit = false;
          } else {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit = false;
          }
        });
    },
    /**
     * This method will submit Payment for purchase
     */
    submitPayment() {
      if (this.isLoadingPaymentSubmit) {
        return;
      }

      this.isLoadingPaymentSubmit = true;
      this.errors = [];
      this.$store
        .dispatch("SUBMIT_PAYMENT")
        .then((resp) => {
          if (resp.data.errors) {
            this.errors = resp.data.errors;
            this.isLoadingPaymentSubmit = false;
            return;
          }
          this.$store.commit("updatePaymentResponse", resp.data);
          if (resp.data.type == "memberCredit") {
            this.member.creditBalance.balance.monetary =
              resp.data.updatedBalance.monetary;
          }
          if (resp.data.type == "pointCredit") {
            this.member.pointsBalance.balance.monetary =
              resp.data.updatedBalance.monetary;
          }
          this.isPaymentSubmitted = true;
          this.isLoadingPaymentSubmit = false;
        })
        .catch((error) => {
          this.errors = [];
          console.error(error.response);
          if (error.response) {
            for (let i in error.response.data) {
              if (typeof error.response.data[i] == "object") {
                for (let j in error.response.data[i]) {
                  this.errors.push(error.response.data[i][j]);
                }
              } else {
                this.errors.push(error.response.data[i]);
              }
            }
            this.isLoadingPaymentSubmit = false;
            // }
          } else if (error.request) {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit = false;
          } else {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit = false;
          }
        });
    },
    getBenefits() {
      this.$store.commit("updateBenefitDetails", {});
      this.$store.dispatch("START_LOADING");
      this.$store
        .dispatch("GET_BENEFIT")
        .then(() => {
          this.$store.dispatch("DISTRIBUTE_DISCOUNT");
          this.$store.dispatch("STOP_LOADING");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("STOP_LOADING");
        });
    },
  },
};
</script>
